@charset "utf-8";

$link-color: #6272a4;

$serif              : 'Fira Mono', sans-serif;
$sans-serif-narrow  : 'Playfair Display', serif;

$global-font-family : $serif;
$header-font-family : $sans-serif-narrow;

$border-color: rgba(255, 255, 255, 0);

.site-title {
	font-size: 2em;
}

.initial-content {
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQ4MCIgaGVpZ2h0PSI2NTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+ICAgIDxwYXRoIGQ9Ik03MzEuMjA3IDY0OS44MDJDOTM1LjQ4NCA2NDIuMTQgMTQ4MCAzMzcuMzI1IDE0ODAgMTgwLjg4OGMwLTE1Ni40MzgtMzA5Ljc0NC0zNi4wNTUtNzIwLTM2LjA1NVMwLTE3NC40ODMgMCAxMzUuMTQ0YzAgMzA5LjYyNyA1MjYuOTMgNTIyLjMyIDczMS4yMDcgNTE0LjY1OHoiIGZpbGw9IiNGNkY4RkEiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
	background-size: cover;
	background-position-x: 20vw;
	background-position-y: -10vh;
	background-repeat: no-repeat;
}

.panel-heading {
	font-family: 'Fira Mono', sans-serif;
	font-size: 1em;
	padding: 10px;
	text-decoration: none;
}

.panel {
	border: solid 1px #efefef;
	border-radius: 10px;
}

.panel-body {
	color: #f8f8f2;
	font-size: 0.8em;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
}

.panel-heading:hover {
	text-decoration: none ;
}

.projects .panel-body {
	min-height: 300px;
}

.grid {
	margin: 0 auto;
}

.grid-item {
	width: 400px;
	margin-bottom: 25px;
	max-width: 100%;
}

.darkmode-toggle {
	z-index: 100;
}

.greedy-nav {
	background: none !important;
}

.button {
	display:block;
	padding:1em 3em;
	border:0.16em solid #6272a4;
	margin: 2em auto;
	box-sizing: border-box;
	text-decoration:none;
	text-transform:uppercase;
	font-family:'Roboto',sans-serif;
	font-weight:600;
	color: #6272a4;
	text-align:center;
	transition: all 0.15s;
	max-width: 700px;
}

.button:hover {
	color:#efefef;
	background-color: #6272a4;
}

.greedy-nav .visible-links a:before {
	height: 1px !important;
}

.masthead__menu-item {
	font-family: 'Fira Mono', sans-serif;
}

.darkmode--activated {
	.panel {
		border: solid 1px #efefef2a;
	}

	.panel-body, .panel-heading {
		background-color: #efefef14 !important;
	}

	body, h1, h2, h3, h4, h5, h6, .greedy-nav a {
		color: #e8e8e8 !important;
	}

	.archive__item-excerpt, p, li, .page__meta, .taxonomy__index a, .taxonomy__count {
		color: #a6a6a6 !important;
	}

	code {
		background-color: #efefef14 !important;
	}
}


@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials
